@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url(./assets/fonts/Nunito/Nunito-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoBold";
  src: local("NunitoBold"),
    url(./assets/fonts/Nunito/Nunito-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSemiBold";
  src: local("NunitoSemiBold"),
    url(./assets/fonts/Nunito/Nunito-SemiBold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Nunito", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
